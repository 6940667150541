import { IRequestParams } from '@/types';

export const SCHOOLS_PAGINATION_LIMIT = 10;
export const CATEGORIES_PAGINATION_LIMIT = 10;

export const schoolsParams = (offset: number, order: Array<string[]>): IRequestParams => ({
  params: {
    filter: {
      limit: SCHOOLS_PAGINATION_LIMIT,
      offset,
      order: order.length ? order : [ ["name", "asc"] ]
    },
    includeCount: true 
  }
});

export const categoriesParams = (offset: number, order: Array<string[]>, status?: string): IRequestParams => ({
  params: {
    filter: {
      limit: CATEGORIES_PAGINATION_LIMIT,
      offset,
      order: order.length ? order : [ ["name", "asc"] ],
      where: { status }
    },
    includeCount: true 
  }
});