<template>
  <div class="bg-white rounded-5 px-50 py-35">
    <div
      class="inline-flex items-center mb-25 text-grey-fp-50 cursor-pointer"
      @click="router.push({ name: routesNames.adminSettings })"
    >
      <AppIcon
        name="arrow-thin-left"
        class="mr-10"
      />
      <p class="text-md">Back to Settings</p>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <AppIcon
          name="school"
          size="30"
          class="text-blue-ap-100 mr-20"
        />
        <p class="text-2xl">Schools</p>
      </div>
      <AppButton
        type="primary"
        class="mr-10 px-30"
        icon-size="16"
        icon="plus"
        size="small"
        @click="onAddNewSchool"
      >
        Add New School
      </AppButton>
    </div>

    <SchoolsList
      ref="schoolListRef"
      @edit="onEdit"
      @remove="openConfirmationModal"
    />
  </div>

  <!-- D E L E T E  C O N F I R M A T I O N -->
  <portal
    v-if="isOpenConfirmDeleteModal"
    to="admin"
  >
    <AppModal @cancel="discardDelete">
      <div class="w-full min-w-280 max-w-400">
        <p class="text-dark-cl-20 text-md mb-8">Delete School</p>
        <p class="text-grey-fp-60 mb-40">Are you sure you want to delete this school?</p>
        <div class="flex items-center justify-end">
          <AppButton
            type="primary"
            size="mini"
            class="px-20 mr-10"
            @click="discardDelete"
          >
            Close
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20"
            @click="confirmDelete"
          >
            Yes
          </AppButton>
        </div>
      </div>
    </AppModal>
  </portal>

  <!-- C R E A T E  &  U P D A T E  M O D A L -->
  <portal
    v-if="isOpenCreateUpdateModal"
    to="admin"
  >
    <AppModal @cancel="closeCreateUpdateModal">
      <div class="w-full">
        <p class="text-dark-cl-20 text-md mb-28">{{ mode === 'create' ? 'Create' : 'Edit' }} School</p>

        <!-- F O R M -->
        <form
          class="max-w-430"
          @submit.prevent="handleSave"
        >
          <AppInput
            v-model="v$.name.$model"
            name="name"
            class="mb-28"
            maxlength="140"
            :error="errorMessages.name"
            @focus="clearErrorField('name')"
          />

          <div class="flex items-center mb-34">
            <AppSelect
              v-model="v$.gradeFrom.$model"
              option-key="label"
              key-value="value"
              class="w-full"
              :options="gradesFromOptions"
              :error="errorMessages.gradeFrom"
              @focus="clearErrorField('gradeFrom')"
            />

            <span class="text-posey-blue mx-15">to</span>

            <AppSelect
              v-model="v$.gradeTo.$model"
              option-key="label"
              key-value="value"
              class="w-full"
              :options="gradesToOptions"
              :error="errorMessages.gradeTo"
              @focus="clearErrorField('gradeTo')"
            />
          </div>
        </form>

        <!-- A C T I O N S -->
        <div class="flex items-center justify-end">
          <AppButton
            size="mini"
            plain
            type="primary"
            class="px-20 mr-5"
            :disabled="disabledUpdateAction && mode === 'edit'"
            @click="handleSave"
          >
            {{ mode === 'create' ? 'Save' : 'Save changes' }}
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20 ml-5"
            @click="closeCreateUpdateModal"
          >
            Close
          </AppButton>
        </div>
      </div>
    </AppModal>
  </portal>
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, toRefs, computed, nextTick } from 'vue';
  import { useToast } from "vue-toastification";
  import { useVuelidate } from "@vuelidate/core";
  import { isEqual } from 'lodash';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import SchoolsList from './components/SchoolsList.vue';

  import { routesNames, router } from '@/router';
  import { ISchool, ISchoolForm, TIndexedObject, IErrorFields } from '@/types';
  import { vuex } from '@/store';
  import { SCHOOLS_PAGINATION_LIMIT } from './api-params';
  import { schoolService } from '@/services';
  import { capitalizeFirstLetter, clearErrorField, validateField, handleSetErrors, getChangedData } from '@/core/helper-functions';
  import { schoolsFormRules } from './validation-rules';

  export default defineComponent({
    name: 'Schools',

    components: { AppButton, AppIcon, AppModal, SchoolsList, AppInput, AppSelect },

    setup() {
      const toast = useToast();
      const state = reactive({
        schoolForm: {
          name: '',
          gradeFrom: {},
          gradeTo: {}
        } as ISchoolForm,
        originalSchoolForm: {} as ISchoolForm,
        originalSchoolGrades: [] as Array<string>,
        gradesList: [] as Array<TIndexedObject>
      });
      const schoolListRef = ref(null);
      let isOpenConfirmDeleteModal = ref<boolean>(false);
      let isOpenCreateUpdateModal = ref<boolean>(false);
      let schoolId = ref<number | string>('');
      let forUpdateSchoolsList = ref<boolean>(false);
      const mode = ref<'create' | 'edit'>('create');

      const errorMessages = reactive<IErrorFields>({
        name: '',
        gradeFrom: '',
        gradeTo: ''
      });

      const { schoolForm, gradesList, originalSchoolForm, originalSchoolGrades } = toRefs(state);

      const v$ = useVuelidate(schoolsFormRules, schoolForm.value);

      const disabledUpdateAction = computed<boolean>(() => {
        return isEqual(
          { name: schoolForm.value.name, grades: gradesForSubmit() },
          { name: originalSchoolForm.value.name, grades: originalSchoolGrades.value });
      });

      const gradesFromOptions = computed<TIndexedObject[]>(() => {
        if (schoolForm.value.gradeTo?.value) {
          const gradeToIndex = gradesList.value.findIndex((el: TIndexedObject) => {
            return el.value === schoolForm.value.gradeTo?.value;
          });

          return gradesList.value.filter((el: TIndexedObject, i: number) => i < gradeToIndex);
        }
        return gradesList.value;
      });

      const gradesToOptions = computed<TIndexedObject[]>(() => {
        if (schoolForm.value.gradeFrom?.value) {
          const gradeFormIndex = gradesList.value.findIndex((el: TIndexedObject) => {
            return el.value === schoolForm.value.gradeFrom?.value;
          });
          return gradesList.value.slice(gradeFormIndex + 1); // to remove selected grade from item
        }

        return gradesList.value;
      });

      function gradesForSubmit() {
        const startFromIndex = gradesList.value.findIndex((el: TIndexedObject) => {
          return el.value === schoolForm.value.gradeFrom?.value;
        });

        const startToIndex = gradesList.value.findIndex((el: TIndexedObject) => {
          return el.value === schoolForm.value.gradeTo?.value;
        });

        return gradesList.value.filter((el: TIndexedObject, i: number) => {
          return i >= startFromIndex && i <= startToIndex;
        })
          .map((el: TIndexedObject) => el.value);
      }

      function openConfirmationModal(id: string) {
        isOpenConfirmDeleteModal.value = true;
        schoolId.value = id;
      }

      async function openCreateUpdateModal() {
        isOpenCreateUpdateModal.value = true;

        if (!gradesList.value.length) {
          return await fetchGradesList();
        }
      }

      function closeCreateUpdateModal() {
        isOpenCreateUpdateModal.value = false;
      }

      function discardDelete() {
        closeConfirmationModal();
        schoolId.value = '';
      }

      function setEdit({ grades, name, id }: ISchool) {
        const gradeFrom = gradesList.value.find((el: TIndexedObject) => grades[0] === el.value);
        const gradeTo = gradesList.value.find((el: TIndexedObject) => {
          return grades[grades.length - 1] === el.value;
        });

        mode.value = 'edit';
        schoolId.value = id;
        schoolForm.value.name = name;
        schoolForm.value.gradeFrom = gradeFrom ? gradeFrom : {};
        schoolForm.value.gradeTo = gradeTo ? gradeTo : {};
        originalSchoolGrades.value = grades;
        originalSchoolForm.value = { ...schoolForm.value };
      }

      function resetSchoolForm() {
        schoolForm.value.name = '';
        schoolForm.value.gradeFrom = {};
        schoolForm.value.gradeTo = {};
      }

      async function onEdit(payload: ISchool) {
        await openCreateUpdateModal();
        setEdit(payload);
      }

      async function handleSave() {

        if (await v$.value.$validate()) {
          if (mode.value === 'create') {
            await createSchool();
          } else {
            await updateSchool();
          }
          closeCreateUpdateModal();
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }

      }

      async function createSchool() {
        vuex.setLoading(true);

        return schoolService.createSchool({ name: schoolForm.value.name, grades: gradesForSubmit() })
          .then(() => {
            toast.success('School has been created');
            vuex.setLoading(false);
            closeCreateUpdateModal();
            return (schoolListRef.value as any).fetchSchools();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function updateSchool() {
        vuex.setLoading(true);

        return schoolService.updateSchool(
          schoolId.value as string,
          getChangedData(
            { name: originalSchoolForm.value.name, grades: originalSchoolGrades.value },
            { name: schoolForm.value.name, grades: gradesForSubmit() }
          ) as ISchoolForm)
          .then(() => {
            toast.success('School has been updated');
            vuex.setLoading(false);
            closeCreateUpdateModal();
            return (schoolListRef.value as any).fetchSchools();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function confirmDelete() {
        vuex.setLoading(true);

        return schoolService.deleteSchool(schoolId.value as string)
          .then(() => {
            toast.success('School has been deleted');
            vuex.setLoading(false);
            closeConfirmationModal();
            (schoolListRef.value as any).schoolsTotal -= 1; // to avoid problem with pagination 
            nextTick(() => {

              (schoolListRef.value as any).fetchSchools();
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      function closeConfirmationModal() {
        isOpenConfirmDeleteModal.value = false;
      }

      function onAddNewSchool() {
        resetSchoolForm();
        mode.value = 'create';
        openCreateUpdateModal();
      }

      async function fetchGradesList() {
        vuex.setLoading(true);

        return schoolService.fetchGrades()
          .then((res: string[]) => {
            gradesList.value = res.map((el: string) => {
              return { label: capitalizeFirstLetter(el), value: el };
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      return {
        schoolListRef,
        routesNames,
        router,
        isOpenConfirmDeleteModal,
        isOpenCreateUpdateModal,
        mode,
        gradesToOptions,
        gradesFromOptions,
        errorMessages,
        forUpdateSchoolsList,

        v$,
        disabledUpdateAction,

        SCHOOLS_PAGINATION_LIMIT,

        discardDelete,
        confirmDelete,
        onEdit,
        openConfirmationModal,
        closeCreateUpdateModal,
        handleSave,
        onAddNewSchool,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>