<template>
  <div class="mt-20">
    <AppTable
      :dataset="schoolsList"
      :headers="tableHeaders"
      class="schools-table"
      rowHeight="50px"
      type="admin"
      theme="primary"
      show-actions
      @sort-by="handleSort"
    >
      <!-- R O W S -->

      <template #grades="{ row }">
        <div class="flex items-center">
          <span>{{ row.grades[0] }} - {{ row.grades[row.grades.length - 1] }}</span>
        </div>
      </template>
      <!-- A C T I O N S -->
      <template #actions="{ row, handleHidePopper }">
        <ul class="actions-wrapper py-10">
          <li
            v-for="(action, i) in actions"
            :key="i"
            class="action flex items-center cursor-pointer py-7 px-10"
            :class="action.customClass"
            @click="action.handler(row, handleHidePopper)"
          >
            <AppIcon
              :name="action.icon"
              size="12"
              class="mr-10"
            />
            <span>{{ action.title }}</span>
          </li>
        </ul>
      </template>
    </AppTable>

    <!-- P A G I N A T I O N -->
    <div
      v-if="schoolsList.length"
      class="flex justify-between items-center pt-20 border-t border-grey-fp-10"
    >
      <PaginationCounter
        :offset="offset"
        :limit="SCHOOLS_PAGINATION_LIMIT"
        :total="schoolsTotal"
        class="text-posey-blue"
      />

      <v-pagination
        v-model:current-page="currentPage"
        :page-size="SCHOOLS_PAGINATION_LIMIT"
        layout="prev, pager, next"
        hide-on-single-page
        :total="schoolsTotal"
        @current-change="onCurrentPageChanged"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onBeforeMount, reactive, toRefs } from 'vue';
  import { useToast } from "vue-toastification";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { routesNames, router } from '@/router';
  import { ISchool, ISchoolRes } from '@/types';
  import { vuex } from '@/store';
  import { schoolService } from '@/services';
  import { SCHOOLS_PAGINATION_LIMIT, schoolsParams } from '../api-params';

  export default defineComponent({
    name: 'SchoolsList',

    components: { AppIcon, AppTable, PaginationCounter },

    emits: ['edit', 'remove'],

    setup(props, { emit }) {
      const toast = useToast();
      const state = reactive({
        schoolsList: [] as ISchool[],
        schoolsTotal: 0 as number,
        sortListBy: [] as Array<string[]>,
      });
      let isOpenConfirmDeleteModal = ref<boolean>(false);
      const currentPage = ref<number>(1);
      const skipRefetchSchools = ref<boolean>(false); // needed for avoid removing and pagination

      const { schoolsList, schoolsTotal } = toRefs(state);

      const tableHeaders = computed(() => [
        { property: 'name', label: 'School', sortable: true, minWidth: 650 },
        { property: 'grades', label: 'Grade', sortable: true, minWidth: 250 }
      ]);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: onEdit, customClass: 'text-dark-cl-20' },
        { title: 'Remove', icon: 'trash-alt', handler: onRemove, customClass: 'text-error' },
      ]);

      const offset = computed<number>(() => {
        return (currentPage.value - 1) * SCHOOLS_PAGINATION_LIMIT;
      });

      function onCurrentPageChanged() {
        if (!skipRefetchSchools.value) {
          fetchSchools();
        }
      }

      function handleSort({ orderBy, order }: any) {
        // Set appropriate values to order property
        if (order) {
          state.sortListBy = [[orderBy, order]];
        } else {
          state.sortListBy = [];
        }

        fetchSchools();
      }

      function onRemove({ id }: ISchool, hideTooltipCb: any) {
        emit('remove', id);
        skipRefetchSchools.value = true;
        hideTooltipCb();
      }

      function onEdit(payload: ISchool, hideTooltipCb: any) {
        emit('edit', payload);
        hideTooltipCb();
      }

      async function fetchSchools() {
        vuex.setLoading(true);

        return await schoolService.fetchSchools(schoolsParams(offset.value, state.sortListBy))
          .then((res: ISchoolRes) => {
            schoolsList.value = res.data;
            schoolsTotal.value = res.totalCount;
            skipRefetchSchools.value = false;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(() => {
        fetchSchools();
      });

      return {
        schoolsList,
        routesNames,
        router,
        tableHeaders,
        actions,
        isOpenConfirmDeleteModal,
        offset,
        schoolsTotal,
        currentPage,

        SCHOOLS_PAGINATION_LIMIT,

        onCurrentPageChanged,
        fetchSchools,
        handleSort
      };
    }

  });
</script>

<style lang="scss" scoped>
  .schools-table {
    max-height: 500px;
  }
</style>